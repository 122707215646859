/* eslint-disable unicorn/no-null */
import { Calendar } from 'primereact/calendar';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dateFilterTemplate = (options: any) => {
  return (
    <Calendar
      value={options.value}
      onChange={(event) => options.filterCallback(event.value, options.index)}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );
};

export default dateFilterTemplate;