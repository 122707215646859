import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken, selectSalesSteeringRemarks } from "../../../store/selectors";
import { useEffect } from "react";
import { getSalesSteeringRemarks } from "../../../store/actions";
import { formatDate, makeDate } from "../../../utilities/formatting";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styles from './sales-steering-remarks-list.module.scss';

interface ISalesSteeringRemarkListProperties {
  salesSteeringId: number;
}

function SalesSteeringRemarkList({salesSteeringId}: ISalesSteeringRemarkListProperties) {
  const dispatch = useDispatch();
  const salesSteeringRemarks = useSelector(selectSalesSteeringRemarks)
  .filter((ssr) => ssr.salesSteeringId === salesSteeringId)
  .map((salesSteeringRemark) => ({
    ...salesSteeringRemark,
    startDate: makeDate(salesSteeringRemark.startDate),
    endDate: makeDate(salesSteeringRemark.endDate)
  }));
  const accessToken = useSelector(selectAccessToken);

  useEffect(() => {
    if (accessToken && !salesSteeringRemarks.some((ssr) => ssr.salesSteeringId === salesSteeringId)) {
      dispatch(getSalesSteeringRemarks(salesSteeringId));
    }
  }, [accessToken]);

  return (
    <>
    {salesSteeringRemarks.length === 0 ? (
      <ProgressSpinner />
      ) : (
        <div className={styles.subTable}>
          <DataTable
            sortField="startDate"
            sortOrder={1}
            value={salesSteeringRemarks}
          >
            <Column
              field="startDate"
              sortable
              dataType="date"
              header="Start Date"
              body={(entry) => formatDate(entry.startDate)}
            ></Column>
            <Column
              field="endDate"
              sortable
              dataType="date"
              header="End Date"
              body={(entry) => formatDate(entry.endDate)}
            ></Column>
            <Column
              field="remark"
              sortable
              header="Remark"
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );

}

export default SalesSteeringRemarkList;