/* eslint-disable unicorn/no-null */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken, selectBookingRestrictions } from '../../../store/selectors';
import { getBookingRestrictions } from '../../../store/actions';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Column } from 'primereact/column';
import { formatDateTime, makeDate, makeBookability, makeStatus, formatDate } from '../../../utilities/formatting';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { PAGINATOR_ROWS, PAGINATOR_ROWS_PER_PAGE, PAGINATOR_TEMPLATE } from '../../constants/paginator-settings';
import dateFilterTemplate from '../../shared/date-filter-template';
import bookabilityFilterTemplate from '../../shared/bookability-filter-template';
import statusFilterTemplate from '../../shared/status-filter-template';

const filters = {
  accommodationCode: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  contractId: {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  startDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  endDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  status: {value: null, matchMode: FilterMatchMode.IN,},
  remark: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  daysBeforeServiceStartsFrom: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
  },
  daysBeforeServiceStartsTill: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]
  },
  bookability: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  createdDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  modifiedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
};
function BookingRestrictionsList() {
  const dispatch = useDispatch();

  const bookingRestrictions = useSelector(selectBookingRestrictions).map((bookingRestriction) => ({
    ...bookingRestriction,
    startDate: makeDate(bookingRestriction.startDate),
    endDate: makeDate(bookingRestriction.endDate),
    createdDate: makeDate(bookingRestriction.createdDate),
    modifiedDate: makeDate(bookingRestriction.modifiedDate),
    status: makeStatus(bookingRestriction.status),
    bookability: makeBookability(bookingRestriction.bookability),
  }));
  const accessToken = useSelector(selectAccessToken);

  useEffect(() => {
    if (bookingRestrictions.length === 0 && accessToken) {
      dispatch(getBookingRestrictions());
    }
  }, [accessToken]);

  return (
    <>
      {bookingRestrictions.length === 0 ? (
        <ProgressSpinner />
      ) : (
        <DataTable
          paginator
          filters={filters}
          sortField="accommodationCode"
          sortOrder={1}
          rowsPerPageOptions={PAGINATOR_ROWS_PER_PAGE}
          rows={PAGINATOR_ROWS}
          value={bookingRestrictions}
          currentPageReportTemplate="Total: {totalRecords}"
          paginatorTemplate={PAGINATOR_TEMPLATE}
        >
          <Column field="accommodationCode" sortable filter header="Acco."></Column>
          <Column field="contractId" sortable filter header="Contract Id"></Column>
          <Column
            field="startDate"
            dataType="date"
            body={(entry) => formatDate(entry.startDate)}
            sortable
            filter
            filterElement={dateFilterTemplate}
            header="Start Date"
          ></Column>
          <Column
            field="endDate"
            dataType="date"
            body={(entry) => formatDate(entry.endDate)}
            sortable
            filter
            filterElement={dateFilterTemplate}
            header="End Date"
          ></Column>
          <Column
            field="status"
            sortable
            filter
            header="Status"
            filterElement={(entry) => statusFilterTemplate(entry, bookingRestrictions)}
            showFilterMatchModes={false}
          ></Column>
          <Column field="remark" sortable filter header="Remark"></Column>
          <Column field="daysBeforeServiceStartsFrom" sortable filter header="From days"></Column>
          <Column field="daysBeforeServiceStartsTill" sortable filter header="Till days"></Column>
          <Column
            field="bookability"
            sortable
            filter
            header="Bookable"
            filterElement={(entry) => bookabilityFilterTemplate(entry, bookingRestrictions)}
            showFilterMatchModes={false}
          ></Column>
          <Column
            field="createdDate"
            dataType="date"
            body={(entry) => formatDateTime(entry.createdDate)}
            sortable
            filter
            filterElement={dateFilterTemplate}
            header="Created"
          ></Column>
          <Column
            field="modifiedDate"
            sortable
            dataType="date"
            header="Modified"
            body={(entry) => formatDateTime(entry.modifiedDate)}
          ></Column>
        </DataTable>
      )}
    </>
  );
}

export default BookingRestrictionsList;
