import { Dispatch } from '@reduxjs/toolkit';
import { setSalesSteerings } from '../actions';
import { AppState } from '../store';

export function getSalesSteerings(): ReduxAction {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const {
      general: { appConfiguration, accessToken },
    } = getState();

    try {
      const data = await fetch(`${appConfiguration.REACT_APP_API_SALES_CONFIGURATION}/api/salesSteering`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const SalesSteerings = await data.json();
      dispatch(setSalesSteerings(SalesSteerings));
    } catch (error) {
      console.error(error);
    }
  };
}