import {
  useMsal,
  // eslint-disable-next-line import/named
  IMsalContext,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAccessToken } from '../store/actions';
import Unauthenticated from './unathenticated/unauthenticated';

import Main from './main/main';

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (isAuthenticated) {
      const msalContext = { instance, accounts } as IMsalContext;
      dispatch(getAccessToken(msalContext));
    }
  }, [isAuthenticated]);

  return (
    <div className={'p-component'}>
      <AuthenticatedTemplate>
        <Main />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Unauthenticated />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
