// eslint-disable-next-line import/named
import { IMsalContext } from '@azure/msal-react';
import { Dispatch } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { setAccessToken } from '../actions/general';

export function getAccessToken(msal: IMsalContext): ReduxAction {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const { instance, accounts } = msal;
    const {
      general: { appConfiguration },
    } = getState();

    const request = {
      scopes: ['user.read'],
      authority: `${appConfiguration!.REACT_APP_AUTHORITY}/${appConfiguration!.REACT_APP_TENANT_ID}`,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    try {
      let response = await instance.acquireTokenSilent(request);

      if (response.accessToken) {
        dispatch(setAccessToken({ accessToken: response.idToken }));
      } else {
        response = await instance.acquireTokenPopup(request);
        dispatch(setAccessToken({ accessToken: response.idToken }));
      }
    } catch (error) {
      console.error(error);
    }
  };
}
