export const formatDate = (value: Date | string) => {
  return typeof value === 'string'
    ? ''
    : value.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
};

export const formatDateTime = (value: Date | string) => {
  return typeof value === 'string'
    ? ''
    : value.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
};

export const makeDate = (value: string) => {
  return value === null || value === '0001-01-01T00:00:00' ? '' : new Date(value);
};

export const makeStatus = (value: number) => {
  switch (value) {
    case 0: {
      return 'OK';
    }
    case 1: {
      return 'Warning';
    }
    case 2: {
      return 'Error';
    }
    default: {
      return 'No status';
    }
  }
};

export const makeBookability = (value: number) => {
  switch (value) {
    case 0: {
      return 'Yes';
    }
    default: {
      return 'No';
    }
  }
};
