// eslint-disable-next-line import/extensions, import/no-unresolved
import { IAppConfig } from './type/app-config';

export const msalConfig = (appConfig: IAppConfig) => {
  return {
    auth: {
      clientId: appConfig.REACT_APP_CLIENT_ID,
      authority: `${appConfig.REACT_APP_AUTHORITY}/${appConfig.REACT_APP_TENANT_ID}`,
      redirectUri: appConfig.REACT_APP_REDIRECT_URL,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  };
};

export const loginRequest = {
  scopes: ['User.Read'],
};
