import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { general } from './actions/general';
import logger from 'redux-logger';
import { salesConfiguration } from './actions/sales-configuration';

const makeStore = () =>
  configureStore({
    reducer: {
      [general.name]: general.reducer,
      [salesConfiguration.name]: salesConfiguration.reducer,
    },
    // eslint-disable-next-line unicorn/prefer-spread
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    devTools: true,
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export default makeStore;
