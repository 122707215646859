import { Dispatch } from '@reduxjs/toolkit';
import { setSalesSteeringRemarks } from '../actions';
import { AppState } from '../store';

export function getSalesSteeringRemarks(salesSteeringId: number): ReduxAction {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const {
      general: { appConfiguration, accessToken },
    } = getState();
    try {
      const request = `${appConfiguration.REACT_APP_API_SALES_CONFIGURATION}`
        + `/api/salesSteering/remarks/${salesSteeringId}`;
      const data = await fetch(request, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      let SalesSteeringRemarks = await data.json();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      SalesSteeringRemarks = SalesSteeringRemarks.map((remark: any) => ({
        ...remark,
        salesSteeringId: salesSteeringId
      }));
      dispatch(setSalesSteeringRemarks(SalesSteeringRemarks));
    } catch (error) {
      console.error(error);
    }
  };
}