import React from 'react';
import styles from './error-route.module.scss';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

function ErrorRoute() {
  return (
    <div className={styles.errorRoute}>
      Error, go{' '}
      <Link to={'/'}>
        <Button label="back" className="p-button-link" />
      </Link>
    </div>
  );
}

export default ErrorRoute;
