import React, { useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from 'primereact/button';
import styles from './sign-out.module.scss';

const SignOut = () => {
  const { instance, accounts } = useMsal();

  const handleLogout = async () => {
    await instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  const name = useMemo(() => accounts[0]?.name || '', [accounts]);

  return (
    <div className={styles.utilities}>
      {name}
      <Button link icon="pi pi-power-off" onClick={() => handleLogout()} />
    </div>
  );
};

export default SignOut;
