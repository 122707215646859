/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MultiSelect } from 'primereact/multiselect';

const bookabilityFilterTemplate = (options: any, object: any) => {
  const bookabilityOptions = object
    .map((b: { bookability: any; }) => b.bookability)
    .filter((value: any, index: any, self: any | any[]) => self.indexOf(value) === index);
  return (
    <MultiSelect
      value={options.value}
      options={bookabilityOptions}
      onChange={(event) => options.filterCallback(event.value)}
      placeholder="Select bookability"
    />
  );
};

export default bookabilityFilterTemplate;