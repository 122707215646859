import React from 'react';

function SalesConfiguration() {

  return (
      <h1>Sales Configuration Monitoring Tool</h1>
  );
}

export default SalesConfiguration;
