import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth-config';
import { Button } from 'primereact/button';

function Unauthenticated() {
  const { instance } = useMsal();

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest).catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className={'p-component'}>
      <div className="m-5">
        <h1>Sales Configuration UI</h1>
        <p>You are not signed in! Please sign in.</p>
        <Button icon="pi pi-sign-in" className={'p-button'} label={'Sign in'} onClick={() => handleLogin()} />
      </div>
    </div>
  );
}

export default Unauthenticated;
