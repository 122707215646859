import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import App from './components/app';
import { Provider } from 'react-redux';
import makeStore from './store/store';
import { getAppConfiguration } from './store/actions';
import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth-config';

const startApp = async () => {
  const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);
  const store = makeStore();
  await store.dispatch(getAppConfiguration());
  const msalInstance = new PublicClientApplication(
    msalConfig(store.getState().general.appConfiguration!) as Configuration
  );

  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <Helmet>
          <title>Sales Configuration UI</title>
        </Helmet>
        <Provider store={store}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

startApp();
