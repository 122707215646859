import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../store';
// eslint-disable-next-line import/extensions, import/no-unresolved
import { IAppConfig } from '../../type/app-config';

export interface IGeneralState {
  appConfiguration: IAppConfig;
  accessToken?: string;
}

const initialState: IGeneralState = {
  appConfiguration: {},
};

export const general = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setAppConfiguration(state, action) {
      state.appConfiguration = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload.accessToken;
    },
  },
});

export const { setAppConfiguration, setAccessToken } = general.actions;

export const selectAppConfiguration = (state: AppState) => state.general.appConfiguration;
export const selectAccessToken = (state: AppState) => state.general.accessToken;

export default general.reducer;
