import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../store';

export interface ISalesConfigurationState {
  playerAttributes: IPlayerAttribute[];
  salesSteerings: ISalesSteering[];
  salesSteeringRemarks: ISalesSteeringRemark[];
  bookingRestrictions: IBookingRestriction[];
}

export interface IPlayerAttribute {
  accommodationCode: string;
  publicationCode: string;
  remark: string;
  isEnabled: boolean;
  externalId: number;
  createdDate: string;
  modifiedDate: string;
}

export interface ISalesSteering {
  id: number;
  accommodationCode: string;
  publicationCode: string;
  startDate: string;
  endDate: string;
  contractId: number;
  transportHubTypeName: string;
  transportHubs: string;
  createdDate: string;
  modifiedDate: string;
}

export interface ISalesSteeringRemark {
  remark: string;
  startDate: string;
  endDate: string;
  salesSteeringId: number;
}

export interface IBookingRestriction {
  accommodationCode: string;
  contractId: string;
  startDate: string;
  endDate: string;
  status: number;
  remark: string;
  daysBeforeServiceStartsFrom: number;
  daysBeforeServiceStartsTill: number;
  bookability: number;
  createdDate: string;
  modifiedDate: string;
}

const initialState: ISalesConfigurationState = {
  playerAttributes: [],
  salesSteerings: [],
  salesSteeringRemarks: [],
  bookingRestrictions: [],
};

export const salesConfiguration = createSlice({
  name: 'salesConfiguration',
  initialState,
  reducers: {
    setPlayerAttributes(state, action) {
      state.playerAttributes = action.payload;
    },
    setSalesSteerings(state, action) {
      state.salesSteerings = action.payload;
    },
    setSalesSteeringRemarks(state, action) {
      if (!state.salesSteeringRemarks.some((ssr) => ssr.salesSteeringId == action.payload[0].salesSteeringId)) {
        state.salesSteeringRemarks = [...state.salesSteeringRemarks, ...action.payload];
      }
    },
    setBookingRestrictions(state, action) {
      state.bookingRestrictions = action.payload;
    },
  },
});

export const { setPlayerAttributes } = salesConfiguration.actions;
export const selectPlayerAttributes = (state: AppState) => state.salesConfiguration.playerAttributes;

export const { setSalesSteerings } = salesConfiguration.actions;
export const selectSalesSteerings = (state: AppState) => state.salesConfiguration.salesSteerings;

export const { setSalesSteeringRemarks } = salesConfiguration.actions;
export const selectSalesSteeringRemarks = (state: AppState) => state.salesConfiguration.salesSteeringRemarks;

export const { setBookingRestrictions } = salesConfiguration.actions;
export const selectBookingRestrictions = (state: AppState) => state.salesConfiguration.bookingRestrictions;

export default salesConfiguration.reducer;
