import React from 'react';
import styles from './main.module.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorRoute from './error-route/error-route';
import Header from '../header/header';
import SalesConfiguration from './sales-configuration/sales-configuration';
import PlayerAttributesList from './player-attributes-list/player-attributes-list';
import SalesSteeringList from './sales-steering-list/sales-steering-list';
import BookingRestrictionsList from './booking-restrictions-list/booking-restrictions-list';

function Main() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <div>
          <Header />
          <div className={styles.main}>
            <SalesConfiguration />
          </div>
        </div>
      ),
      errorElement: <ErrorRoute />,
    },
    {
      path: '/player-attributes',
      element: (
        <div>
          <Header />
          <div className={styles.main}>
            <PlayerAttributesList />
          </div>
        </div>
      ),
      errorElement: <ErrorRoute />,
    },
    {
      path: '/sales-steering',
      element: (
        <div>
          <Header />
          <div className={styles.main}>
            <SalesSteeringList />
          </div>
        </div>
      ),
      errorElement: <ErrorRoute />,
    },
    {
      path: '/booking-restrictions',
      element: (
        <div>
          <Header />
          <div className={styles.main}>
            <BookingRestrictionsList />
          </div>
        </div>
      ),
      errorElement: <ErrorRoute />,
    },
  ]);
  return (
    <div className={styles.app}>
      <RouterProvider router={router} />
    </div>
  );
}

export default Main;
