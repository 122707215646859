import React, { useMemo, useState } from 'react';
import styles from './header.module.scss';
import { TabMenu } from 'primereact/tabmenu';
import { useLocation } from 'react-router-dom';
import SignOut from './sign-out/sign-out';

const menus = [
  {
    label: 'Sales Configuration Monitoring',
    url: '/',
  },
  {
    label: 'Player Attributes',
    url: '/player-attributes',
  },
  {
    label: 'Sales Steering',
    url: '/sales-steering',
  },
  {
    label: 'Booking Restrictions',
    url: '/booking-restrictions',
  },
];

function Header() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const [itemsSource] = useState(menus);

  const currentIndex = useMemo(() => {
    return itemsSource.findIndex((item) => item.url === currentUrl);
  }, [currentUrl, itemsSource]);

  const [activeIndex, setActiveIndex] = useState(currentIndex);

  return (
    <div className={styles.header}>
      <div className={styles.home}>
        <img alt="logo" src={window.location.origin + '/logo-sunwebgroup.svg'} height="40" className="mr-2"></img>
      </div>
      <TabMenu
        className={styles.tabMenu}
        model={itemsSource}
        activeIndex={activeIndex}
        onTabChange={(event) => setActiveIndex(event.index)}
      />
      <SignOut />
    </div>
  );
}

export default Header;
